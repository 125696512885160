<template>
  <div class="content">
    <div id="canvasBox" class="image_box" :style="{height:canvasData.image_Height+'px'}" @click="handleClick">
      <canvas ref="canvas" :width="canvasData.image_Height+'px'" :height="canvasData.image_Height+'px'" style="display: none;"></canvas>
      <img :src="details.bg_img" class="image-layer-bg">
      <img v-for="(item,index) in orderList" :style="{ 'z-index': orderList.length - index }" :key="index" :src="item.pic" class="image-layer">
    </div>
    <div class="sku_content">
      <div class="type_box">
        <div
          v-for="(item,index) in projectList"
          :key="index"
          class="types"
          :class="proIndex===index?'active':''"
          @click="proChange(index)"
        >{{ item.name }}</div>
      </div>
      <div class="good_content">
        <div class="flex justify-between goods_box">
          <!-- 规格 -->
          <div class="pup-sku-area">
            <div v-if="skuList.length" class="sku-box">
              <div v-for="(skuGroupItem, skuGroupItemIndex) in skuGroupList" :key="skuGroupItemIndex">
                <div v-for="(skuLine, key) in skuGroupItem" :key="key" class="items sku-text">
                  <span class="sku-kind">{{ key }}</span>
                  <div class="con">
                    <span
                      v-for="skuLineItem in skuLine"
                      v-if="!isSkuLineItemNotOptional(allProperties,selectedPropObj,key,skuLineItem,propKeys)"
                      :key="skuLineItem"
                      class="sku-choose-item"
                      :class="['item-item',selectedPropList.indexOf(key + ':' + skuLineItem) !== -1?'active':'',
                               isSkuLineItemNotOptional(allProperties,selectedPropObj,key,skuLineItem,propKeys)? 'dashed' : '']"
                      @click="toChooseItem(skuGroupItemIndex, skuLineItem, key)"
                    >{{ skuLineItem }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="title">产品详情</div>
      <div class="rich">
        <div v-html="details.detail" />
      </div>
    </div>
    <el-dialog title="清单" :visible.sync="dialogTableVisible" width="90%">
      <el-table :data="orderList">
        <el-table-column property="skuName" label="项目" />
        <el-table-column property="values" label="型号" />
      </el-table>
      <div class="total">
        <div class="price">总价：￥{{ orderPrice }}</div>
        <div class="btn" @click="submit">下单</div>
      </div>
    </el-dialog>
    <div class="bottom">
      <div class="btn" @click="saveOrder">产品清单</div>
      <div class="btn" @click="clear">复位</div>
    </div>
  </div>
</template>
<script src="https://js.cdn.aliyun.dcloud.net.cn/dev/uni-app/uni.webview.1.5.2.js"></script>
  <script>
import wx from "weixin-js-sdk";
import html2canvas from "html2canvas";
import Vconsole from "vconsole";
import {
  queryQuoteProdDetail,
  doQuoteOrder,
  doQuoteProdRecord,
  updateQuoteProdRecord
} from "@/api/index";
export default {
  data() {
    return {
      dialogTableVisible: false,
      gridData: [],
      canvasData: {
        image_Height: 0
      },
      template: "",
      path: "",
      title: "清单",
      show: false,
      prodId: "",
      details: {},
      skuList: [],
      defaultPrice: null,
      defaultSku: {
        price: 0,
        picUrl: ""
      },
      propKeys: [],
      selectedPropObj: {},
      skuGroup: {},
      allProperties: [],
      findSku: false,
      skuGroupList: [],
      selectedPropObjList: [],
      selectedProperties: "",
      selectedPropList: [],
      selectedPropShowList: [],
      prodRecordId: null,
      projectList: [],
      proIndex: 0,
      orderList: [],
      orderPrice: 0,
      canvasData: {
        image_Width: 0,
        image_Height: 0
      },
      prodId: "",
      session: "",
      lookId: "",
      imgUrl: ""
    };
  },
  watch: {
    // 监视搜索词变化
    "$route.query": {
      immediate: true,
      handler(val) {
        console.log(val, "----");
        this.prodId = val.prodId;
        this.session = val.session;
        this.lookJoin();
        this.getInfo();
      }
    }
  },
  mounted() {
    //   new Vconsole()
    this.canvasData.image_Height = window.innerWidth;
  },
  beforeDestroy() {
    this.lookExit();
  },
  methods: {
    handleClick(event) {
      const x = event.clientX;
      const y = event.clientY;
      // 获取canvas元素和上下文
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      // 清空canvas
      ctx.clearRect(
        0,
        0,
        this.canvasData.image_Height,
        this.canvasData.image_Height
      );
      // 使用Promise.all确保所有图片都加载完成
      const loadImagePromises = this.orderList.map(item => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve(image);
          image.onerror = reject;
          image.src = item.pic;
          image.setAttribute("crossOrigin", "");
        });
      });
      Promise.all(loadImagePromises)
        .then(orderList => {
          // 逐层绘制图片到canvas
          for (let i = orderList.length - 1; i >= 0; i--) {
            ctx.drawImage(
              orderList[i],
              0,
              0,
              this.canvasData.image_Height,
              this.canvasData.image_Height
            );
            // 获取点击位置的像素颜色
            const pixel = ctx.getImageData(x, y, 1, 1).data;
            // 判断颜色是否为透明
            if (
              pixel[0] === 0 &&
              pixel[1] === 0 &&
              pixel[2] === 0 &&
              pixel[3] === 0
            ) {
              // 如果是透明色，则继续查找下一层
              continue;
            } else {
              // 如果不是透明色，则执行相应操作
              console.log("Clicked on image:", this.orderList[i]);
              let currentData = this.orderList[i];
              let projectIndex = this.projectList.findIndex(
                (item, index) => item.id == currentData.project_id
              );
              this.skuList = this.projectList[projectIndex].skuList;
              this.orderList = this.orderList.sort(
                (a, b) => a.project_id - b.project_id
              );

              let currentIndex = this.skuList.findIndex(
                item => item.sku_id === currentData.sku_id
              );
              let nextData = null;
              if (currentIndex == this.skuList.length - 1) {
                nextData = this.skuList[0];
              } else {
                nextData = this.skuList[currentIndex + 1];
              }

              if (this.proIndex !== projectIndex) {
                this.proIndex = projectIndex;
                this.groupSku();
              }
              this.orderList = this.orderList.sort(
                (a, b) => a.project_id - b.project_id
              );
              this.orderList[projectIndex] = nextData;
              this.selectedPropList = nextData.properties.split(";");
              this.skuList = this.projectList[projectIndex].skuList;
              return;
            }
          }
          // 如果点击在空白区域，则执行相应操作
          console.log("Clicked on empty space");
        })
        .catch(error => {
          console.error("Error loading images:", error);
        });
    },
    lookExit() {
      updateQuoteProdRecord({
        id: this.lookId,
        session: this.session
      }).then(res => {
        console.log(res);
      });
    },
    lookJoin() {
      doQuoteProdRecord({
        prodId: this.prodId,
        session: this.session
      }).then(res => {
        this.lookId = res.data.data;
        console.log(res);
      });
    },
    submit() {
      if (!this.imgUrl) {
        return this.$message.error("图片生成中请稍后再试");
      }
      let arr = [];
      this.orderList.map(item => {
        arr.push({
          skuId: item.sku_id
        });
      });
      var that = this;
      doQuoteOrder({
        prodId: that.prodId,
        session: that.session,
        image: this.imgUrl,
        skuList: arr
      }).then(res => {
        if (res.data.status == 0) {
          // that.$message.success('下单成功')
          that.dialogTableVisible = false;
          // wx.miniProgram.navigateBack({delta: 1});
          wx.miniProgram.navigateTo({
            url: "/pages/quotation/my"
          });
          wx.miniProgram.postMessage({
            data: {
              code: 111
            }
          });
        }
      });
    },
    getName(data) {
      const valueArray = [];
      for (const item of data) {
        const parts = item.split(":");
        if (parts.length === 2) {
          valueArray.push(parts[1]);
        }
      }
      return valueArray.join(",");
    },
    saveOrder() {
      if (this.details.quoteVip != 1) {
        return this.$message.error("无此权限，请联系厂家申请授权。");
      }
      this.imgUrl = "";
      this.orderPrice = 0;
      this.orderList.map(item => {
        this.orderPrice += item.price;
        item.values = this.getName(item.properties.split(";"));
      });
      this.dialogTableVisible = true;
      let shareContent = document.getElementById("canvasBox");
      var that = this;
      html2canvas(shareContent, {
        width: shareContent.clientWidth, //dom 原始宽度
        height: shareContent.clientHeight,
        scrollY: 0,
        scrollX: 0,
        useCORS: true,
        scale: 1
      }).then(function(canvas) {
        that.imgUrl = canvas.toDataURL("image/" + "jpg");
        console.log(that.imgUrl);
      });
    },
    clear() {
      this.defaultSku = {};
      this.proIndex = 0;
      this.skuList = this.details.projectList[0].skuList;
      this.orderList = [];
      this.groupSku();
      console.log(this.orderList);
    },
    proChange(e) {
      this.proIndex = e;
      this.skuList = this.details.projectList[e].skuList;
      this.groupSku();
    },
    async getInfo() {
      let res = await queryQuoteProdDetail({
        prodId: this.prodId,
        session: this.session
      });
      res.data.data.projectList.map(item => {
        item.skuList.map(obj => {
          obj.skuName = item.name;
        });
      });
      this.details = res.data.data;
      document.title = this.details.prod_name + "-DIY";
      this.projectList = res.data.data.projectList;
      this.skuList = this.details.projectList[0].skuList;
      this.orderList = [];
      this.details.projectList.map(item => {
        this.orderList.push(item.skuList[0]);
      });
      this.groupSku(1);
    },
    setSku(sku, findSku) {
      this.findSku = findSku;
      this.defaultSku = sku;
    },
    groupSku(data) {
      const skuList = this.skuList;
      if (skuList.length == 1 && !skuList[0].properties) {
        this.defaultSku = skuList[0];
        this.findSku = true;
        if (this.pageType || this.isJionGroup) {
          this.setSku(this.defaultSku, this.findSku);
        }
        return;
      }
      const skuGroupList = [];
      const skuGroup = {};
      const allProperties = [];
      const propKeys = [];
      const selectedPropObj = {};
      const selectedPropObjList = [];

      var defaultSku = null;
      for (var i = 0; i < skuList.length; i++) {
        var isDefault = false;
        if (
          (!defaultSku && skuList[i].skuId == this.skuId) ||
          (!defaultSku && skuList[i].price == this.defaultPrice)
        ) {
          defaultSku = skuList[i];
          isDefault = true;
        }
        var properties = skuList[i].properties; // 版本:公开版;颜色:金色;内存:64GB
        allProperties.push(properties);
        var propList = properties.split(";"); // ["版本:公开版","颜色:金色","内存:64GB"]

        for (var j = 0; j < propList.length; j++) {
          var propval = propList[j].split(":"); // ["版本","公开版"]
          var props = skuGroup[propval[0]]; // 先取出 版本对应的值数组
          // 如果当前是默认选中的sku，把对应的属性值 组装到selectedProp
          if (isDefault) {
            propKeys.push(propval[0]);
            selectedPropObj[propval[0]] = propval[1];
            const selectedPropObjItem = {};
            selectedPropObjItem[propval[0]] = propval[1];
            selectedPropObjList.push(selectedPropObjItem);
          }
          if (props == undefined) {
            props = []; // 假设还没有版本，新建个新的空数组
            props.push(propval[1]); // 把 "公开版" 放进空数组
          } else {
            if (props.indexOf(propval[1]) === -1) {
              // 如果数组里面没有"公开版"
              props.push(propval[1]); // 把 "公开版" 放进数组
            }
          }
          skuGroup[propval[0]] = props; // 最后把数据 放回版本对应的值
          const propListItem = {};
          propListItem[propval[0]] = props;
          skuGroupList.push(propListItem);
        }
      }
      this.defaultSku = defaultSku;
      this.propKeys = propKeys;
      this.selectedPropObj = selectedPropObj;
      this.skuGroup = skuGroup;
      this.selectedPropObjList = selectedPropObjList;
      this.skuGroupList = this.unique(skuGroupList);
      this.allProperties = allProperties;
      this.parseSelectedObjToVals(skuList, data);
      this.$forceUpdate();

      console.log(
        "this.skuGroupList",
        this.skuGroupList,
        this.selectedPropList
      );
    },
    parseSelectedObjToVals(skuList, data) {
      const selectedPropObjList = this.selectedPropObjList;
      let selectedProperties = "";
      const selectedPropList = [];
      const selectedPropShowList = [];
      for (let i = 0; i < selectedPropObjList.length; i++) {
        const selectedPropObjItem = selectedPropObjList[i];
        for (const key in selectedPropObjItem) {
          if (Object.hasOwnProperty.call(selectedPropObjItem, key)) {
            selectedPropList.push(key + ":" + selectedPropObjItem[key]);
            selectedPropShowList.push(selectedPropObjItem[key]);
            selectedProperties += key + ":" + selectedPropObjItem[key] + ";";
          }
        }
      }
      selectedProperties = selectedProperties.substring(
        0,
        selectedProperties.length - 1
      );
      this.selectedPropList = selectedPropList;
      this.selectedPropShowList = selectedPropShowList;
      this.selectedProperties = selectedProperties;
      this.selectedPropObjList = selectedPropObjList;
      var findSku = false;
      for (var i = 0; i < skuList.length; i++) {
        if (skuList[i].properties == selectedProperties) {
          findSku = true;
          this.defaultSku = skuList[i];
          break;
        }
      }
      this.findSku = findSku;
      if (!data) {
        if (this.orderList.length > 0) {
          let name = this.defaultSku.skuName;
          const index = this.orderList.findIndex(function(item) {
            return item.skuName === name;
          });
          if (index > -1) {
            this.orderList.splice(index, 1);
            this.orderList.push(this.defaultSku);
          } else {
            this.orderList.push(this.defaultSku);
          }
        } else {
          this.orderList.push(this.defaultSku);
        }
      }
      if (this.pageType || this.isJionGroup) {
        this.setSku(this.defaultSku, this.findSku);
      }
    },
    toChooseItem(skuGroupItemIndex, skuLineItem, key) {
      this.selectedPropObjList[skuGroupItemIndex][key] = skuLineItem;
      this.selectedPropObj[key] = skuLineItem;
      console.log(this.selectedPropObjList, this.selectedPropObj);
      this.parseSelectedObjToVals(this.skuList);
      if (this.pageType || this.isJionGroup) {
        this.setSku(this.defaultSku, this.findSku);
      }
    },
    isSkuLineItemNotOptional(allProperties, propObj, key, item, propKeys) {
      var selectedPropObj = Object.assign({}, propObj);
      var properties = "";
      selectedPropObj[key] = item;
      for (let j = 0; j < propKeys.length; j++) {
        properties += propKeys[j] + ":" + selectedPropObj[propKeys[j]] + ";";
      }
      properties = properties.substring(0, properties.length - 1);
      for (let i = 0; i < allProperties.length; i++) {
        if (properties == allProperties[i]) {
          return false;
        }
      }
      for (let i = 0; i < allProperties.length; i++) {
        if (allProperties[i].indexOf(item) >= 0) {
          return true;
        }
      }
      return false;
    },
    unique(arr) {
      const map = {};
      arr.forEach(item => {
        const obj = {};
        Object.keys(item)
          .sort()
          .map(key => {
            obj[key] = item[key];
          });
        map[JSON.stringify(obj)] = item;
      });
      return Object.keys(map).map(key => JSON.parse(key));
    }
  }
};
</script>
  <style lang="scss"  scoped>
::v-deep {
  .wscnph {
    width: 100%;
  }
  .el-dialog__header,
  .el-dialog__body {
    padding: 10px !important;
  }
}

.content {
  padding-bottom: 60px;
  height: 100%;
  overflow-y: scroll;
  .total {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .price {
      font-weight: 400;
    }

    .btn {
      width: 70px;
      height: 25px;
      text-align: center;
      line-height: 25px;
      background-color: #b98144;
      color: #fff;
      border-radius: 4rpx;
    }
  }
  .bottom {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    right: 0;
    border-top: 1px solid #ccc;
    text-align: right;
    background-color: #fff;
    z-index: 1000;
    .btn {
      width: 70px;
      height: 25px;
      display: inline-block;
      background-color: #b98144;
      color: #fff;
      margin-left: 8px;
      text-align: center;
      line-height: 25px;
      border-radius: 4px;
    }
  }
  .sku_content {
    padding: 5px;
  }
  .good_content {
    .goods_box {
      margin-top: 10px;
      width: 95%;
    }
  }

  .pup-sku-area {
    width: calc(100% - 110px);
    // max-height: 245px;
    // overflow-y: scroll;
  }

  .pup-sku-area .sku-box {
    // max-height: 245px;
    // overflow-y: scroll;
  }

  .pup-sku-area .sku-kind {
    font-size: 14px;
    color: #333333;
  }

  .pup-sku-area .con {
    // padding-right: 20px;
    margin-top: 5px;
    width: 100%;
  }

  .pup-sku-area .sku-choose {
    overflow: hidden;
    margin-bottom: 3px;
  }

  .sku-choose-item {
    display: inline-block;
    padding: 5px 8px;
    overflow: hidden;
    text-align: center;
    margin: 0 8px 0 0;
    border-radius: 8px;
    color: #585858;
    background-color: #f6f6f6;
    font-size: 14px;
  }

  .sku-choose-item.active {
    background-color: #eddece;
    color: #b98144;
    border: 1px solid #b98144 !important;
  }

  .sku-choose-item.gray {
    background-color: #f9f9f9;
    color: #ddd;
  }

  .sku-choose-item.dashed {
    border: 1px dashed #ccc;
  }
  .image_box {
    width: 100%;
    // height: 500px;
    position: relative;
  }
  .image-layer-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 保持图片比例并填充容器 */
  }
  .image-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* 保持图片比例并填充容器 */
  }
  .type_box {
    width: 100%;
    overflow-x: auto;
    display: flex;
    margin-top: 10px;
    font-size: 14px;
    .types {
      display: inline-block;
      padding: 5px 8px;
      background-color: #ccc;
      color: #fff;
      margin-right: 8px;
    }

    .active {
      border: 1px solid #b98144;
      color: #b98144;
    }
  }
}
</style>

