import axios from "axios";
// 是否显示重新登录
const instance = axios.create({
  // 创建axios实例，在这里可以设置请求的默认配置
  timeout: 10000, // 设置超时时间10s
  baseURL: '/api'
  // baseURL: 'https://scrm.sellbaby.net/api'
});
// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
instance.defaults.headers.post["Content-Type"] =
  "application/json";

/** 添加请求拦截器 **/
instance.interceptors.request.use(
  config => {
    // do something before request is sent
    // 是否需要设置 token
    // 是否需要防止数据重复提交
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?' + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = url;
    }
    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put' ||  config.method === 'delete')) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data
      }
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

/** 添加响应拦截器  **/

export default instance
