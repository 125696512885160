import request  from '@/utils/request'
export function queryQuoteProdDetail(data) {
    return request({
      url: '/vertX/queryQuoteProdDetail',
      method: 'post',
      data
    })
  }
export function doQuoteOrder(data) {
  return request({
    url: '/vertX/doQuoteOrder',
    method: 'post',
    data
  })
}
export function doQuoteProdRecord(data) {
  return request({
    url: '/vertX/doQuoteProdRecord',
    method: 'post',
    data
  })
}
export function updateQuoteProdRecord(data) {
  return request({
    url: '/vertX/updateQuoteProdRecord',
    method: 'post',
    data
  })
}